import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Table,
  Label,
  Input,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import ContractForm from '../../components/ContractForm';
import token from '../../util/setAuthToken';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import UpdateNsuContract from 'components/UpdateNsuContract';

const CreateContract = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [disableInput, setDisableInput] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [nsu, setNsu] = useState('');
  const [parcels, setParcels] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [disableInputValue, setDisableInputValue] = useState(false);
  const [contract, setContract] = useState({
    name: '',
    email: '',
    birth_date: '',
    cpf_cnpj: '',
    mobile: '',
    zipcode: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    contract_number: '',
    contract_value: '',
    amount_charged: '',
    installments: 12,
    customer_id: '',
    idpartner: '',
  });
  const [banks, setBanks] = useState([]);
  const [contractId, setContractId] = useState(null);

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parseMaskCurrency(value) {
    return value.toString().replace('.', ',');
  }

  async function toogleModalNsu() {
    setOpenModal(!openModal);
  }

  async function submitNsu() {
    if (!nsu) {
      toastError('Nsu obrigatorio');
      return;
    }

    showLoader();

    try {
      const response = await api.post(
        `payments/${contractId}/create_payment_with_nsu`,
        {
          nsu: nsu,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const { data } = response;
      toastSuccess(data.message);

      //Redirecionar
      if (query.get('kind')) {
        props.history.push('/admin/fgts-contracts');
      } else {
        props.history.push('/admin/contracts');
      }

      hideLoader();
    } catch (e) {
      console.log(e);
      hideLoader();
      toastError(
        'Ops, tivemos um erro ao salvar o nsu, por favor tente novamente!'
      );
    }
  }

  async function onSubmit(data) {
    const amountCharge = Number(parseCurrency(contract.amount_charged));
    const contractValue = Number(parseCurrency(contract.contract_value));
    // if (amountCharge <= contractValue) {
    //   toastError(
    //     'Valor Cobrado não pode ser menor ou igual ao Valor do Contrato.'
    //   );
    //   return;
    // }

    if (contract.payment_method == 'pos' && !contract.nsu) {
      toastError('Nsu é obrigatorio');
      return;
    }

    if (!query.get('kind')) {
      if (!contract.month) {
        toastError(
          'Data de vencimento da fatura do cartão que será utilizado é obrigatorio'
        );
        return;
      }

      if (!contract.kind_credit_card) {
        toastError('Tipo de cartão que será utilizado é obrigatorio');
        return;
      }
    }

    showLoader();
    console.log(amountCharge);
    data.installments = contract.installments;
    try {
      data = {
        contract: {
          // contract_number: contract.contract_number,
          name: contract.name,
          store_name: localStorage.getItem('credit_card_store_name'),
          contract_value: contractValue,
          amount_charged: amountCharge,
          nsu: contract.nsu,
          month: contract.month,
          kind_integrator:
            query.get('kind') && query.get('kind') === 'fgts' ? 0 : null, //Qista
          kind_credit_card: contract.kind_credit_card,
          installments: contract.installments,
          payment_method: contract.payment_method,
          customer_id: contract.customer_id,
          customer_attributes: {
            email: contract.email,
            birth_date: contract.birth_date,
            mobile: contract.mobile,
            rg: contract.rg,
            entity_attributes: {
              name: contract.name,
              cpf_cnpj: contract.cpf_cnpj.replace(/[^0-9]+/g, ''),
              address_attributes: {
                zip_code: contract.zipcode,
                street: contract.street,
                number: contract.number,
                district: contract.district,
                city: contract.city,
                state: contract.state,
                complement: contract.complement,
              },
            },
          },
        },
      };

      let params = '';
      if (query.get('customerServiceId')) {
        params = `?simulation_id=${query.get('customerServiceId')}`;
        // params = `?customer_service_id=${query.get("customerServiceId")}`
      }

      const response = await api.post(
        `contracts/${attendance.kind_bank === 'uy3' ? 'create_uy3' : ''}${params}`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();
      setContractId(response.data.id);
      if (response.data.payment_method == 'pos') {
        // toogleModalNsu()
        if (query.get('kind')) {
          props.history.push(
            `/admin/fgts-contracts?&${
              query.get('isCdc') ? `isCdc=${query.get('isCdc')}` : ''
            }`
          );
        } else {
          props.history.push('/admin/contracts');
        }
      } else {
        if (query.get('kind')) {
          props.history.push(
            `/admin/fgts-contracts?&${
              query.get('isCdc') ? `isCdc=${query.get('isCdc')}` : ''
            }`
          );
        } else {
          props.history.push('/admin/contracts');
        }
        toastSuccess('Contrato cadastro com sucesso');
      }
    } catch (error) {
      hideLoader();
      console.log(error.response.data.error);
      let message = null;

      if (error.response) {
        if (error.response.data.error) {
          message = error.response.data.error;

          message.forEach((e) => {
            let m = e.replace('Base: ', '');
            toastError(m);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, tivemos um erro no cadastro do contrato, por favor tente novamente!';
      }
      toastError(message);
    }
  }

  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          setContract({
            ...contract,
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
        } else {
          setContract({
            ...contract,
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  function handleOnChangeContractValue(e) {
    setContract({
      ...contract,
      contract_value: parseCurrency(e),
    });
  }

  function handleOnChangeBank(e) {
    setContract({ ...contract, bank_name: e.value });
  }

  function handleOnChangeAmountCharged(e) {
    setContract({
      ...contract,
      amount_charged: parseCurrency(e),
    });
  }

  function handleOnChange(e) {
    if (
      e.target.name === 'contract_value' ||
      e.target.name === 'amount_charged'
    ) {
      console.log(parseMaskCurrency(e.target.value));
      setContract({
        ...contract,
        [e.target.name]: parseMaskCurrency(e.target.value),
      });
    } else if (e.target.name == 'cpf_cnpj') {
      if (e.target.value.length < 15) {
        let cpf_cnpj = e.target.value;
        if (cpf_cnpj.length >= 12) {
          cpf_cnpj = cpf_cnpj
            .replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
        } else {
          cpf_cnpj = cpf_cnpj.replace(/(\d{3})(\d)/, '$1.$2');
        }
        setContract({ ...contract, [e.target.name]: cpf_cnpj });
      }
    } else if (e.target.name == 'mobile') {
      if (e.target.value.length < 16) {
        let mobile = e.target.value;

        if (mobile.length > 10) {
          mobile = mobile
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');
        } else {
          mobile = mobile.replace(/^(\d{2})(\d)/, '($1) $2');
        }
        setContract({ ...contract, [e.target.name]: mobile });
      }
    } else {
      setContract({ ...contract, [e.target.name]: e.target.value });
    }
  }

  async function handleOnBlurCpf(
    event,
    amount_charged = null,
    contract_value = null,
    installments = null,
    attendance = null
  ) {
    console.log(event);
    const { value } = event.target;

    if (value.length > 0) {
      const cpf = value.replace(/[^0-9]+/g, '');

      try {
        let { data } = await api.get(
          `contracts/get_customer_by_cpf?q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (data.length > 0) {
          data = data[0];
        }

        console.log(attendance);
        if (amount_charged && contract_value && installments) {
          setContract({
            ...contract,
            customer_id: data.id,
            name: data.name,
            cpf_cnpj: data.cpf_cnpj,
            birth_date: data.birth_date,
            mobile: data.mobile,
            rg: data.rg,
            mother_name: data.mother_name,
            father_name: data.father_name,
            email: data.email,
            zipcode: data.address.zip_code,
            street: data.address.street,
            number: data.address.number,
            district: data.address.district,
            city: data.address.city,
            state: data.address.state,
            complement: data.address.complement,
            bank_id: data.bank_account ? data.bank_account.id : null,
            bank_name: data.bank_account
              ? `${data.bank_account.number_bank} - ${data.bank_account.name_bank}`
              : null,
            agency_account: data.bank_account
              ? data.bank_account.agency_account
              : null,
            agency_digit: data.bank_account
              ? data.bank_account.agency_digit
              : null,
            account_digit: data.bank_account
              ? data.bank_account.account_digit
              : null,
            number_account: data.bank_account
              ? data.bank_account.number_account
              : null,
            kind_account: data.bank_account
              ? data.bank_account.kind_account
              : null,
            kind_pix: data.bank_account ? data.bank_account.kind_pix : null,
            pix: data.bank_account ? data.bank_account.pix : null,
            account_kind: data.bank_account ? data.bank_account.kind : null,
            amount_charged: parseMaskCurrency(amount_charged),
            contract_value: parseMaskCurrency(contract_value),
            value_client: attendance?.value_client
              ? parseMaskCurrency(attendance.value_client)
              : 0,
            value_establishment: attendance?.value_establishment
              ? parseMaskCurrency(attendance.value_establishment)
              : 0,
            installments,
          });

          setDisableInputValue(true);
        } else {
          setContract({
            ...contract,
            customer_id: data.id,
            name: data.name,
            cpf_cnpj: data.cpf_cnpj,
            birth_date: data.birth_date,
            mobile: data.mobile,
            rg: data.rg,
            mother_name: data.mother_name,
            father_name: data.father_name,
            email: data.email,
            zipcode: data.address.zip_code,
            street: data.address.street,
            number: data.address.number,
            district: data.address.district,
            city: data.address.city,
            bank_id: data.bank_account ? data.bank_account.id : null,
            bank_name: data.bank_account
              ? `${data.bank_account.number_bank} - ${data.bank_account.name_bank}`
              : null,
            agency_account: data.bank_account
              ? data.bank_account.agency_account
              : null,
            agency_digit: data.bank_account
              ? data.bank_account.agency_digit
              : null,
            account_digit: data.bank_account
              ? data.bank_account.account_digit
              : null,
            number_account: data.bank_account
              ? data.bank_account.number_account
              : null,
            account_kind: data.bank_account ? data.bank_account.kind : null,
            state: data.address.state,
            complement: data.address.complement,
            value_client: attendance?.value_client
              ? parseMaskCurrency(attendance.value_client)
              : 0,
            value_establishment: attendance?.value_establishment
              ? parseMaskCurrency(attendance.value_establishment)
              : 0,
          });
        }

        setDisableInput(true);
      } catch (e) {
        console.log(e);
        console.log('Ops, houve um erro na busca pelo CPF');
      }
    }
  }

  async function getAttendance(id) {
    showLoader();

    try {
      const response = await api.get(`simulations/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      setAttendance(response.data);
      if (response.data.parcels) {
        setParcels(response.data.parcels);
      }
      handleOnBlurCpf(
        { target: { value: response.data.client_cpf_cnpj } },
        response.data.card_limit,
        response.data.released_amount,
        response.data.installments,
        response.data
      );
      console.log(response);
      hideLoader();
    } catch (error) {
      hideLoader();
    }
  }

  useEffect(() => {
    if (query.get('customerServiceId')) {
      getAttendance(query.get('customerServiceId'));
    }
  }, []);

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Criar um Contrato</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cpf_cnpj"
                          >
                            CPF
                          </label>
                          <Input
                            type="text"
                            name="cpf_cnpj"
                            placeholder="999.999.999-99"
                            className="form-control form-control-alternative"
                            id="cpf_cnpj"
                            disabled={disableInput}
                            mask="999.999.999-99"
                            value={contract.cpf_cnpj}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.cpf_cnpj && (
                            <small className="text-danger">
                              O cpf é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome Completo
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            id="name"
                            name="name"
                            disabled={disableInput}
                            value={contract.name}
                            placeholder="Nome completo"
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                            maxLength={35}
                          />

                          {errors.name && (
                            <small className="text-danger">
                              O nome é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="mother_name"
                          >
                            Nome da Mãe
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            id="mother_name"
                            disabled
                            name="mother_name"
                            placeholder="Nome da Mãe"
                            value={contract && contract.mother_name}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />

                          {errors.name && (
                            <small className="text-danger">
                              O nome da mãe é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      {!query.get('kind') ||
                        (query.get('kind') != 'fgts' && (
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="father_name"
                              >
                                Nome do Pai
                              </label>
                              <input
                                type="text"
                                name="father_name"
                                className="form-control form-control-alternative"
                                id="father_name"
                                disabled
                                value={contract && contract.father_name}
                                ref={register({ required: false })}
                                onChange={handleOnChange}
                              />
                              {errors.email && (
                                <small className="text-danger">
                                  O nome do pai é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        ))}
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control form-control-alternative"
                            id="email"
                            disabled={disableInput}
                            value={contract.email}
                            placeholder="example@example.com"
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              O email é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="birth_date"
                          >
                            Data de nascimento
                          </label>
                          <input
                            type="date"
                            name="birth_date"
                            className="form-control form-control-alternative"
                            id="birth_date"
                            disabled={disableInput}
                            ref={register({ required: true })}
                            value={contract.birth_date}
                            onChange={handleOnChange}
                          />
                          {errors.birth_date && (
                            <small className="text-danger">
                              O data de nascimento é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="mobile"
                          >
                            Celular
                          </label>
                          <Input
                            type="text"
                            name="mobile"
                            className="form-control form-control-alternative"
                            id="mobile"
                            placeholder="(99) 99999-9999"
                            disabled={disableInput}
                            inputRef={register({ required: true })}
                            value={contract.mobile}
                            onChange={handleOnChange}
                          />
                          {errors.mobile && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="rg">
                            RG
                          </label>
                          <Input
                            type="text"
                            name="rg"
                            className="form-control form-control-alternative"
                            id="rg"
                            placeholder=""
                            disabled={disableInput}
                            inputRef={register({ required: true })}
                            value={contract.rg}
                            onChange={handleOnChange}
                          />
                          {errors.rg && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Endereço do Cliente
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="zipcode"
                          >
                            CEP
                          </label>
                          <InputMask
                            name="zipcode"
                            className="form-control form-control-alternative"
                            id="zipcode"
                            placeholder="CEP"
                            type="text"
                            mask="99999-999"
                            onBlur={handleBlurZipCode}
                            inputRef={register({ required: true })}
                            disabled={disableInput}
                            value={contract.zipcode}
                            onChange={handleOnChange}
                          />
                          {errors.zipcode && (
                            <small className="text-danger">
                              O cep é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="street"
                          >
                            Endereço
                          </label>
                          <input
                            name="street"
                            className="form-control form-control-alternative"
                            id="street"
                            placeholder="Endereço"
                            type="text"
                            value={contract.street}
                            onChange={handleOnChange}
                            ref={register({ required: true })}
                            disabled={disableInput}
                          />
                          {errors.street && (
                            <small className="text-danger">
                              O endereço é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number"
                          >
                            Número
                          </label>
                          <input
                            name="number"
                            className="form-control form-control-alternative"
                            id="number"
                            type="number"
                            value={contract.number}
                            onChange={handleOnChange}
                            ref={register({ required: true })}
                            disabled={disableInput}
                          />
                          {errors.number && (
                            <small className="text-danger">
                              O número é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="district"
                          >
                            Bairro
                          </label>
                          <input
                            name="district"
                            className="form-control form-control-alternative"
                            id="district"
                            placeholder="Bairro"
                            type="text"
                            value={contract.district}
                            onChange={handleOnChange}
                            ref={register({ required: true })}
                            disabled={disableInput}
                          />
                          {errors.district && (
                            <small className="text-danger">
                              O bairro é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            Cidade
                          </label>
                          <input
                            name="city"
                            className="form-control form-control-alternative"
                            id="city"
                            placeholder="Cidade"
                            type="text"
                            value={contract.city}
                            onChange={handleOnChange}
                            ref={register({ required: true })}
                            disabled={disableInput}
                          />
                          {errors.city && (
                            <small className="text-danger">
                              A cidade é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Estado
                          </label>
                          <input
                            name="state"
                            className="form-control form-control-alternative"
                            id="state"
                            placeholder="Estado"
                            type="text"
                            value={contract.state}
                            onChange={handleOnChange}
                            ref={register({ required: true })}
                            disabled={disableInput}
                          />
                          {errors.state && (
                            <small className="text-danger">
                              O estado é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="complement"
                          >
                            Complemento
                          </label>
                          <input
                            name="complement"
                            className="form-control form-control-alternative"
                            id="complement"
                            placeholder="Complemento"
                            type="text"
                            value={contract.complement}
                            onChange={handleOnChange}
                            ref={register({ required: false })}
                            disabled={disableInput}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  {!query.get('isCdc') && (
                    <>
                      <h6 className="heading-small text-muted mb-4">
                        Dados de pagamento
                      </h6>
                      <div className="pl-lg-4">
                        {!query.get('kind') || query.get('kind') == 'fgts' ? (
                          false
                        ) : (
                          <Row>
                            <Col lg={'2 mb-4'}>
                              <label
                                className="form-control-label"
                                htmlFor="kind_account"
                              >
                                Tipo de Dado de Pagamento
                              </label>
                              <Input
                                type="select"
                                id="kind_account"
                                name="kind_account"
                                disabled
                                value={contract && contract.kind_account}
                                onChange={handleOnChange}
                              >
                                <option>Selecione o tipo</option>
                                <option value="ted">TED</option>
                                <option value="pix">PIX</option>
                              </Input>
                            </Col>
                            <Col className="d-flex align-items-center text-danger">
                              <h5 className="badge badge-warning">
                                A conta informada deve estar em nome do cliente
                                e apenas pessoa física
                              </h5>
                            </Col>
                          </Row>
                        )}

                        {contract.kind_account == 'pix' &&
                        (!query.get('kind') || query.get('kind') != 'fgts') ? (
                          <Row>
                            <Col lg={'2 mb-4'}>
                              <label
                                className="form-control-label"
                                htmlFor="kind_pix"
                              >
                                Tipo Pix
                              </label>
                              <Input
                                type="select"
                                id="kind_pix"
                                name="kind_pix"
                                disabled
                                value={contract && contract.kind_pix}
                                onChange={handleOnChange}
                              >
                                <option>Selecione o tipo</option>
                                <option value="cpf_cnpj">CPF/CNPJ</option>
                                <option value="email">E-mail</option>
                                <option value="phone">Telefone</option>
                                {/* {
                                    query.get("kind") != 'fgts' && (<option value="random">Dados Bancários (conforme abaixo)</option>)
                                  } */}
                              </Input>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="pix"
                                >
                                  Pix
                                </label>
                                <InputMask
                                  type={
                                    contract &&
                                    (contract.kind_pix == 'cpf_cnpj' ||
                                      contract.kind_pix == 'phone')
                                      ? 'number'
                                      : 'text'
                                  }
                                  name="pix"
                                  disabled
                                  className="form-control form-control-alternative"
                                  id="pix"
                                  placeholder="Pix"
                                  value={contract && contract.pix}
                                  // inputRef={register({ required: true })}
                                  onChange={handleOnChange}
                                />
                                {errors.pix && (
                                  <small className="text-danger">
                                    Pix é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : (
                          <>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="color"
                                    value={{
                                      value: contract.bank_name,
                                      label: contract.bank_name,
                                    }}
                                    options={banks}
                                    isDisabled={disableInput}
                                    onChange={handleOnChangeBank}
                                  />
                                </FormGroup>
                              </Col>
                              {query.get('kind') &&
                                query.get('kind') == 'fgts' && (
                                  <Col className="d-flex align-items-center text-danger">
                                    <h5 className="badge badge-warning">
                                      A conta informada deve estar em nome do
                                      cliente e apenas pessoa física
                                    </h5>
                                  </Col>
                                )}
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="agency_number"
                                  >
                                    Agência
                                  </label>
                                  <InputMask
                                    type="text"
                                    name="agency_account"
                                    className="form-control form-control-alternative"
                                    id="agency_account"
                                    placeholder="9999"
                                    mask="9999"
                                    maskChar=""
                                    disabled={disableInput}
                                    value={contract && contract.agency_account}
                                    onChange={handleOnChange}
                                  />
                                  {errors.agency_account && (
                                    <small className="text-danger">
                                      A conta é obrigatório
                                    </small>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                {/* <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="agency_number"
                                    >
                                      Dígito
                                    </label>
                                    <InputMask
                                      type="text"
                                      name="agency_digit"
                                      className="form-control form-control-alternative"
                                      id="agency_account"
                                      placeholder=""
                                      mask="*"
                                      maskChar=""
                                      disabled={disableInput}
                                      value={contract && contract.agency_digit}
                                      inputRef={register({required: false })}
                                      onChange={handleOnChange}
                                    />
                                  </FormGroup> */}
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="number_account"
                                  >
                                    Conta
                                  </label>
                                  <InputMask
                                    type="text"
                                    name="number_account"
                                    className="form-control form-control-alternative"
                                    id="number_account"
                                    placeholder="9999999"
                                    // mask="9999999"
                                    // maskChar=""
                                    disabled={disableInput}
                                    value={contract && contract.number_account}
                                    onChange={handleOnChange}
                                  />
                                  {errors.number_account && (
                                    <small className="text-danger">
                                      A agência é obrigatório
                                    </small>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="agency_number"
                                  >
                                    Dígito
                                  </label>
                                  <InputMask
                                    type="text"
                                    name="account_digit"
                                    className="form-control form-control-alternative"
                                    id="agency_account"
                                    placeholder="9"
                                    mask="*"
                                    maskChar=""
                                    disabled={disableInput}
                                    value={contract && contract.account_digit}
                                    onChange={handleOnChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="2  mb-4">
                                <Label for="account_kind">Tipo de conta</Label>
                                <Input
                                  type="select"
                                  id="account_kind"
                                  name="account_kind"
                                  disabled={disableInput}
                                  value={contract && contract.account_kind}
                                  onChange={handleOnChange}
                                >
                                  <option>Selecione o tipo</option>
                                  <option value="0">Conta Corrente</option>
                                  <option value="1">Poupança</option>
                                </Input>
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row>
                          {!query.get('kind') && (
                            <>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="month"
                                  >
                                    Vencimento da fatura do cartão que será
                                    utilizado
                                  </label>
                                  <Input
                                    type="select"
                                    id="month"
                                    name="month"
                                    value={contract && contract.month}
                                    onChange={handleOnChange}
                                  >
                                    <option>Selecione</option>
                                    {[
                                      '01',
                                      '02',
                                      '03',
                                      '04',
                                      '05',
                                      '06',
                                      '07',
                                      '08',
                                      '09',
                                      '10',
                                      '11',
                                      '12',
                                      '13',
                                      '14',
                                      '15',
                                      '16',
                                      '17',
                                      '18',
                                      '19',
                                      '20',
                                      '21',
                                      '22',
                                      '23',
                                      '24',
                                      '25',
                                      '26',
                                      '27',
                                      '28',
                                      '29',
                                      '30',
                                      '31',
                                    ].map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="kind_credit_card"
                                  >
                                    Tipo de cartão que será utilizado
                                  </label>
                                  <Input
                                    type="select"
                                    id="kind_credit_card"
                                    name="kind_credit_card"
                                    value={
                                      contract && contract.kind_credit_card
                                    }
                                    onChange={handleOnChange}
                                  >
                                    <option>Selecione</option>
                                    {[
                                      'Cartão de crédito PORTO SEGURO',
                                      'Cartão de crédito BANCO DE REDE (BB, CEF, Itaú, etc.)',
                                      'Cartão de crédito BANCO DIGITAL (Nubank, Inter, C6, etc.)',
                                      'Cartão de crédito LOJA (Magalu, Renner, Casas Bahia, etc.)',
                                      'Cartão Consignado INSS (RMC)',
                                      'Cartão Consignado SIAPE',
                                      'Cartão Benefício GOV. e PREF. (ex.: CREDCESTA)',
                                      'Cartão Benefício INSS',
                                    ].map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                    </>
                  )}

                  <ContractForm
                    contract={contract}
                    hasFgts={
                      query.get('kind') && query.get('kind') == 'fgts'
                        ? true
                        : false
                    }
                    disableInputValueKindPayment={false}
                    disableInputValue={disableInputValue}
                    disableInstallments
                    handleOnChangeContractValue={handleOnChangeContractValue}
                    handleOnChangeAmountCharged={handleOnChangeAmountCharged}
                    handleOnChange={handleOnChange}
                    register={register}
                    errors={errors}
                  />

                  {parcels && parcels.length > 0 && (
                    <Table className="align-items-center justify-content-center">
                      <thead className="">
                        <tr>
                          <th scope="col" class="text-center">
                            Tabela
                          </th>
                          <th scope="col" class="text-center">
                            Faixa
                          </th>
                          <th scope="col" class="text-center">
                            Periodos
                          </th>
                          <th scope="col" class="text-center">
                            Saldo FGTS Retido
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {parcels.map((parcel, index) => (
                          <tr key={index}>
                            <td class="text-center">
                              {attendance?.interest_rate == 2.04
                                ? 'TABELA NORMAL'
                                : attendance?.interest_rate == 1.99
                                ? 'TABELA FLEX 1'
                                : 'TABELA FLEX 2'}
                              - TX {attendance?.interest_rate}%
                            </td>
                            <td class="text-center">
                              Faixa{' '}
                              {parseInt(attendance?.tac_percent) == 15
                                ? 1
                                : parseInt(attendance?.tac_percent) == 20
                                ? 2
                                : parseInt(attendance?.tac_percent) == 25
                                ? 3
                                : 4}{' '}
                              - TC -{parseInt(attendance?.tac_percent)}%
                            </td>
                            <td class="text-center">
                              {new Date(parcel.due_date).toLocaleDateString()}
                            </td>
                            <td class="text-center">
                              {parcel.total_amount.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  <div class="d-flex text-right justify-content-end">
                    {query.get('kind') && query.get('kind') == 'fgts' && (
                      <div className="text-right mr-4">
                        <Button
                          type="button"
                          className="btn btn-secondary px-4"
                          onClick={() => {
                            props.history.push(
                              `/admin/customers/${
                                contract.customer_id
                              }?customerServiceId=${query.get(
                                'customerServiceId'
                              )}&kind=fgts`
                            );
                          }}
                        >
                          <i className="fas fa-paper-plane" /> Voltar
                        </Button>
                      </div>
                    )}
                    <div className="text-right">
                      <Button type="submit" className="btn btn-success px-4">
                        <i className="fas fa-paper-plane" /> Enviar
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <UpdateNsuContract
        open={openModal}
        toogleModal={toogleModalNsu}
        submit={submitNsu}
        handleNsu={setNsu}
        nsu={nsu}
        className={props.className}
      />
    </>
  );
};

export default CreateContract;
