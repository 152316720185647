import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input';
import CommissionCorbanForm from 'components/CommissionCorbanForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { AuthContext } from '../../context/AuthContext';
import FormCommissionFgts from './FormCommissionFgts';

const CorbanForm = ({
  corban,
  contactFields,
  fgtsBasicInterests,
  fgtsCommissions,
  cdcCommissions,
  basicInterests,
  commissions,
  banks,
  titleButton,
  title,
  hasEdit,
  onSubmit,
  handleOnChange,
  handleOnChangeContact,
  handleClickContact,
  handleClickContactRemove,
  handleOnChangeTypeContact,
  handleClickCommissionRemove,
  handleOnChangeCommission,
  handleOnChangeCommissionFgts,
  handleOnChangeCommissionCdc,
  handleOnChangeBank,
  dataAddress,
  insertMoney,
  insertPercentage,
  insertPercentageCorban,
  controller,
  handleOnChangeValueFix,
  handleOnChangeValueFixFgts,
  handleClickCommissionRemoveFgts,
  handleOnChangeInstallmentFields,
  installmentFields,
  setIntallmentFields,
  removeInstallmentFields,
  handleClickInstallmentFields,
  register,
  handleSubmit,
  errors,
  referrers,
  handleOnChangeReferrer,
}) => {
  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [addressDisabled, setAddressDisabled] = useState(true);
  const [disabledBankData, setDisabledBankData] = useState(true);

  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          dataAddress({
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
          setAddressDisabled(true);
        } else {
          dataAddress({
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
          setAddressDisabled(false);
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  async function handleChangeEmail(e) {
    const { value } = e.target;
    handleOnChange(e);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      const { data } = await api.get(
        `contracts/check_email_exist?email=${value}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data.message === 'E-mail já consta no sistema') {
        return toastError('E-mail já consta no sistema');
      }
    }
  }

  async function handleChangeCpf(e) {
    const { value } = e.target;
    handleOnChange(e);
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    if (cpfRegex.test(value)) {
      const cpf = value.replace(/[^0-9]+/g, '');
      const { data } = await api.get(
        `contracts/check_cpf_exist?cpf_cnpj=${cpf}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data.message === 'CPF já consta no sistema') {
        return toastError('CPF já consta no sistema');
      }
    }
  }

  async function handleChangeCnpj(e) {
    const { value } = e.target;
    handleOnChange(e);
    const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    if (cnpjRegex.test(value)) {
      const cnpj = value.replace(/[^0-9]+/g, '');
      const { data } = await api.get(
        `contracts/check_cnpj_exist?cpf_cnpj=${cnpj}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data.message === 'CNPJ já consta no sistema') {
        return toastError('CNPJ já consta no sistema');
      }
    }
  }

  useEffect(() => {
    if (user) {
      if (
        user.role_default === 'master' ||
        user.role_default === 'operational'
      ) {
        setDisabledBankData(false);
      } else {
        setDisabledBankData(true);
      }
    }
  }, [user]);

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">{title}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações do Correspondente Bancário
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="social_reason"
                          >
                            Razão social
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            id="social_reason"
                            name="social_reason"
                            // placeholder="Razão Social"
                            value={corban && corban.social_reason}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />

                          {errors.social_reason && (
                            <small className="text-danger">
                              A razão social é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome Fantasia
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-alternative"
                            id="name"
                            name="name"
                            // placeholder="Nome Fantasia"
                            value={corban && corban.name}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />

                          {errors.name && (
                            <small className="text-danger">
                              O nome fantasia é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cpf_cnpj"
                          >
                            CNPJ
                          </label>
                          <input
                            type="text"
                            name="cpf_cnpj"
                            // placeholder="99.999.999/9999-99"
                            className="form-control form-control-alternative"
                            id="cpf_cnpj"
                            value={corban && corban.cpf_cnpj}
                            ref={register({ required: true })}
                            onChange={handleChangeCnpj}
                          />
                          {errors.cpf_cnpj && (
                            <small className="text-danger">
                              {Object.values(errors.cpf_cnpj)
                                .join('')
                                .replace('[object HTMLInputElement]', '') ===
                              'required'
                                ? 'CNPJ é obrigatório'
                                : `CNPJ ${Object.values(errors.cpf_cnpj)
                                    .join('')
                                    .replace('[object HTMLInputElement]', '')}`}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {controller == 'new' ? (
                    <>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Representante legal
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_cpf"
                              >
                                CPF
                              </label>
                              <input
                                type="text"
                                name="user_cpf"
                                // placeholder="999.999.999-99"
                                className="form-control form-control-alternative"
                                id="user_cpf"
                                value={corban && corban.user_cpf}
                                ref={register({ required: true })}
                                onChange={handleChangeCpf}
                              />
                              {errors.user_cpf && (
                                <small className="text-danger">
                                  {Object.values(errors.user_cpf)
                                    .join('')
                                    .replace(
                                      '[object HTMLInputElement]',
                                      ''
                                    ) === 'required'
                                    ? 'CPF é obrigatório'
                                    : `${Object.values(errors.user_cpf)
                                        .join('')
                                        .replace(
                                          '[object HTMLInputElement]',
                                          ''
                                        )}`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_name"
                              >
                                Nome
                              </label>
                              <input
                                type="text"
                                name="user_name"
                                className="form-control form-control-alternative"
                                id="user_name"
                                value={corban && corban.user_name}
                                // placeholder="Nome Completo"
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                maxLength={35}
                              />
                              {errors.user_name && (
                                <small className="text-danger">
                                  O nome é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_email"
                              >
                                Email do representante (Login de acesso do
                                corban)
                              </label>
                              <input
                                type="user_email"
                                name="user_email"
                                className="form-control form-control-alternative"
                                id="user_email"
                                value={corban && corban.user_email}
                                // placeholder="example@example.com"
                                ref={register({ required: true })}
                                onChange={handleChangeEmail}
                              />
                              {errors.user_email && (
                                <small className="text-danger">
                                  {Object.values(errors.user_email)
                                    .join('')
                                    .replace(
                                      '[object HTMLInputElement]',
                                      ''
                                    ) === 'required'
                                    ? 'Email é obrigatório'
                                    : `Email ${Object.values(errors.user_email)
                                        .join('')
                                        .replace(
                                          '[object HTMLInputElement]',
                                          ''
                                        )}`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_mobile"
                              >
                                Celular
                              </label>
                              <input
                                type="text"
                                name="user_mobile"
                                className="form-control form-control-alternative"
                                id="user_mobile"
                                // placeholder="(99) 99999-9999"
                                value={corban && corban.user_mobile}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                              />
                              {errors.user_mobile && (
                                <small className="text-danger">
                                  O celular é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="name"
                              >
                                Senha
                              </label>
                              <InputMask
                                type="password"
                                name="password"
                                // placeholder="Senha"
                                className="form-control form-control-alternative"
                                id="password"
                                value={corban && corban.password}
                                inputRef={register({
                                  required: hasEdit ? false : true,
                                })}
                                onChange={handleOnChange}
                              />
                              {errors.password && (
                                <small className="text-danger">
                                  {Object.values(errors.password)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'A senha é obrigatorio'
                                    : `${
                                        Object.values(errors.password)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Representante legal
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_cpf"
                              >
                                CPF
                              </label>
                              <InputMask
                                type="text"
                                name="user_cpf"
                                className="form-control form-control-alternative"
                                id="user_cpf"
                                value={corban && corban.user_cpf}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_name"
                              >
                                Nome
                              </label>
                              <input
                                type="text"
                                name="user_name"
                                className="form-control form-control-alternative"
                                id="user_name"
                                value={corban && corban.user_name}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_email"
                              >
                                Email do representante (Login de acesso do
                                corban)
                              </label>
                              <input
                                type="user_email"
                                name="user_email"
                                className="form-control form-control-alternative"
                                id="user_email"
                                value={corban && corban.user_email}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="user_mobile"
                              >
                                Celular
                              </label>
                              <InputMask
                                type="text"
                                name="user_mobile"
                                className="form-control form-control-alternative"
                                id="user_mobile"
                                value={corban && corban.user_mobile}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="name"
                              >
                                Senha
                              </label>
                              <InputMask
                                type="password"
                                name="password"
                                // placeholder="Senha"
                                className="form-control form-control-alternative"
                                id="password"
                                value={corban && corban.password}
                                onChange={handleOnChange}
                              />
                              {errors.password && (
                                <small className="text-danger">
                                  {Object.values(errors.password)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'A senha é obrigatorio'
                                    : `${
                                        Object.values(errors.password)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Endereço do Correspondente Bancário
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="zip_code"
                          >
                            CEP
                          </label>
                          <InputMask
                            name="zip_code"
                            className="form-control form-control-alternative"
                            id="zip_code"
                            // placeholder="CEP"
                            type="text"
                            mask="99999-999"
                            maskChar=""
                            value={corban && corban.zip_code}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                            onBlur={handleBlurZipCode}
                          />
                          {errors.zip_code && (
                            <small className="text-danger">
                              O cep é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="street"
                          >
                            Endereço
                          </label>
                          <input
                            name="street"
                            className="form-control form-control-alternative"
                            id="street"
                            // placeholder="Endereço"
                            type="text"
                            value={corban && corban.street}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.street && (
                            <small className="text-danger">
                              O endereço é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number"
                          >
                            Número
                          </label>
                          <input
                            name="number"
                            className="form-control form-control-alternative"
                            id="number"
                            type="number"
                            value={corban && corban.number}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.number && (
                            <small className="text-danger">
                              O número é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="district"
                          >
                            Bairro
                          </label>
                          <input
                            name="district"
                            className="form-control form-control-alternative"
                            id="district"
                            // placeholder="Bairro"
                            type="text"
                            value={corban && corban.district}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.district && (
                            <small className="text-danger">
                              O bairro é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">
                            Cidade
                          </label>
                          <input
                            name="city"
                            className="form-control form-control-alternative"
                            id="city"
                            // placeholder="Cidade"
                            type="text"
                            value={corban && corban.city}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={addressDisabled}
                          />
                          {errors.city && (
                            <small className="text-danger">
                              A cidade é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="state">
                            Estado
                          </label>
                          <input
                            name="state"
                            className="form-control form-control-alternative"
                            id="state"
                            // placeholder="Estado"
                            type="text"
                            value={corban && corban.state}
                            ref={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={addressDisabled}
                          />
                          {errors.state && (
                            <small className="text-danger">
                              O estado é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="complement"
                          >
                            Complemento
                          </label>
                          <input
                            name="complement"
                            className="form-control form-control-alternative"
                            id="complement"
                            // placeholder="Complemento"
                            type="text"
                            value={corban && corban.complement}
                            ref={register({ required: false })}
                            onChange={handleOnChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Dados Bancários
                  </h6>
                  {disabledBankData && (
                    <h5
                      className="badge badge-warning"
                      style={{ marginLeft: 16 }}
                    >
                      Caso precise alterar os dados, favor informar
                      administrativo@novosaque.com.br
                    </h5>
                  )}
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg={'2 mb-4'}>
                        <label
                          className="form-control-label"
                          htmlFor="kind_pix"
                        >
                          Tipo Pix
                        </label>
                        <Input
                          type="select"
                          id="kind_pix"
                          name="kind_pix"
                          value={corban && corban.kind_pix}
                          onChange={handleOnChange}
                          disabled={disabledBankData}
                        >
                          <option>Selecione o tipo</option>
                          <option value="cpf_cnpj">CPF/CNPJ</option>
                          <option value="email">E-mail</option>
                          <option value="phone">Telefone</option>
                          <option value="random">
                            {'Dados Bancários (conforme abaixo)'}
                          </option>
                        </Input>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="pix">
                            Pix
                          </label>
                          <InputMask
                            type="text"
                            name="pix"
                            className="form-control form-control-alternative"
                            id="pix"
                            placeholder="Pix"
                            value={corban && corban.pix}
                            // inputRef={register({ required: pixRequired })}
                            onChange={handleOnChange}
                            disabled={disabledBankData}
                          />
                          {errors.pix && (
                            <small className="text-danger">
                              Pix é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="agency_number"
                        >
                          Banco
                        </label>
                        <FormGroup>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="bank_name"
                            // placeholder="Selecione o banco"
                            value={{
                              value: corban.bank_name,
                              label: corban.bank_name,
                            }}
                            options={banks}
                            onChange={handleOnChangeBank}
                            isDisabled={disabledBankData}
                          />
                          {errors.agency_account && (
                            <small className="text-danger">
                              O banco é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_account"
                          >
                            Agência
                          </label>
                          <InputMask
                            type="text"
                            name="agency_account"
                            className="form-control form-control-alternative"
                            id="agency_account"
                            // placeholder="9999"
                            mask="9999"
                            maskChar=""
                            value={corban && corban.agency_account}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={disabledBankData}
                          />
                          {errors.agency_account && (
                            <small className="text-danger">
                              A agência é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        {/* <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="agency_digit"
                          >
                            Dígito
                          </label>
                          <InputMask
                            type="text"
                            name="agency_digit"
                            className="form-control form-control-alternative"
                            id="agency_digit"
                            // placeholder="9"
                            mask="*"
                            maskChar=""
                            value={corban && corban.agency_digit}
                            inputRef={register({ required: false })}
                            onChange={handleOnChange}
                          />
                          {errors.agency_digit && (
                            <small className="text-danger">
                              O digito da agência é obrigatório
                            </small>
                          )}
                        </FormGroup> */}
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="number_account"
                          >
                            Conta
                          </label>
                          <InputMask
                            type="text"
                            name="number_account"
                            className="form-control form-control-alternative"
                            id="number_account"
                            // mask="9999999"
                            // maskChar=""
                            value={corban && corban.number_account}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={disabledBankData}
                          />
                          {errors.number_account && (
                            <small className="text-danger">
                              A conta é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="account_digit"
                          >
                            Dígito
                          </label>
                          <InputMask
                            type="text"
                            name="account_digit"
                            className="form-control form-control-alternative"
                            id="account_digit"
                            // placeholder="9"
                            mask="*"
                            maskChar=""
                            value={corban && corban.account_digit}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                            disabled={disabledBankData}
                          />
                          {errors.account_digit && (
                            <small className="text-danger">
                              O digito da conta é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="account_kind">Tipo de conta</Label>
                        <Input
                          type="select"
                          id="account_kind"
                          name="account_kind"
                          value={corban && corban.account_kind}
                          onChange={handleOnChange}
                          disabled={disabledBankData}
                        >
                          <option>Selecione o tipo</option>
                          <option value="0">Conta Corrente</option>
                          <option value="1">Poupança</option>
                        </Input>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Produtos
                  </h6>
                  
                  <Row>
                    <Col lg="2 ">
                      <Label for="has_credit_create">
                        Produto Cartão de Credito
                      </Label>
                      <Input
                        type="select"
                        id="has_credit_create"
                        name="has_credit_create"
                        value={corban && corban.has_credit_create}
                        onChange={handleOnChange}
                      >
                        <option>Selecione o tipo</option>
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                      </Input>
                    </Col>
                    <Col lg="2 ">
                      <Label for="has_fgts_create">
                        Produto FGTS
                      </Label>
                      <Input
                        type="select"
                        id="has_fgts_create"
                        name="has_fgts_create"
                        value={corban && corban.has_fgts_create}
                        onChange={handleOnChange}
                      >
                        <option>Selecione o tipo</option>
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                      </Input>
                    </Col>
                    
                    <Col lg="2 ">
                      <Label for="has_uy3_create">
                        Produto FGTS UY3
                      </Label>
                      <Input
                        type="select"
                        id="has_uy3_create"
                        name="has_uy3_create"
                        value={corban && corban.has_uy3_create}
                        onChange={handleOnChange}
                      >
                        <option>Selecione o tipo</option>
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                      </Input>
                    </Col>
                    <Col lg="2 ">
                      <Label for="has_cdc_create">
                        Produto CDC
                      </Label>
                      <Input
                        type="select"
                        id="has_cdc_create"
                        name="has_cdc_create"
                        value={corban && corban.has_cdc_create}
                        onChange={handleOnChange}
                      >
                        <option>Selecione o tipo</option>
                        <option value="yes">Sim</option>
                        <option value="no">Não</option>
                      </Input>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Cadastro de comissão
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="calculate_type">Método de calculo</Label>
                        <Input
                          type="select"
                          id="calculate_type"
                          name="calculate_type"
                          value={corban && corban.calculate_type}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="0">Método 1</option>
                          {/* <option value="1">Método 2</option> */}
                        </Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="has_commission">
                          Definir uma comissão cartão de credito?
                        </Label>
                        <Input
                          type="select"
                          id="has_commission"
                          name="has_commission"
                          value={corban && corban.has_commission}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="yes">Sim</option>
                          <option value="no">Não</option>
                        </Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="has_secure">Operação com seguro?</Label>
                        <Input
                          type="select"
                          id="has_secure"
                          name="has_secure"
                          value={corban && corban.has_secure}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="yes">Sim</option>
                          <option value="no">Não</option>
                        </Input>
                      </Col>
                    </Row>
                    {/*A base de juros é o primeiro campo da row de cadastrode uma comissão*/}
                    {corban.has_commission == 'yes' ? (
                      <CommissionCorbanForm
                        basicInterests={basicInterests}
                        register={register}
                        hasSecure={corban.has_secure == 'yes'}
                        commissions={commissions}
                        handleOnChangeInstallmentFields={
                          handleOnChangeInstallmentFields
                        }
                        installmentFields={installmentFields}
                        setIntallmentFields={setIntallmentFields}
                        removeInstallmentFields={removeInstallmentFields}
                        handleClickInstallmentFields={
                          handleClickInstallmentFields
                        }
                      />
                    ) : (
                      false
                    )}
                    <br></br>
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="kind_integrator">
                          Tipo de integração fgts?
                        </Label>
                        <Input
                          type="select"
                          id="kind_integrator"
                          name="kind_integrator"
                          value={corban && corban.kind_integrator}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="qista">Qista</option>
                          <option value="uy3">Uy3</option>
                        </Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="kind_table">
                          Definir uma comissão fgts?
                        </Label>
                        <Input
                          type="select"
                          id="kind_table"
                          name="kind_table"
                          value={corban && corban.kind_table}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="base">Base</option>
                          <option value="acordo">Acordo</option>
                          <option value="especial">Especial</option>
                        </Input>
                      </Col>
                    </Row>

                    {corban.kind_table != '' && (
                        corban.kind_integrator == 'qista' ? (
                          <>
                            <hr />
                            <h3 className="mb-4">Tabela FGTS</h3>
                            <h3 className="mb-4">Acima de R$ 250,00</h3>
                            {fgtsCommissions
                              .filter(
                                (item) =>
                                  !item.lower_than_250 && item.kind == corban?.kind_table
                              )
                              .map((commission, index) => (
                                <>
                                  <FormCommissionFgts
                                    handleOnChangeCommissionFgts={
                                      handleOnChangeCommissionFgts
                                    }
                                    insertPercentage={insertPercentage}
                                    register={register}
                                    errors={errors}
                                    index={index}
                                    commission={commission}
                                    withoutLabel={true}
                                  />
                                </>
                              ))}
                            <h3 className="mb-4">De R$ 100,00 a R$ 249,99</h3>
                            {fgtsCommissions
                              .filter(
                                (item) =>
                                  item.lower_than_250 && item.kind == corban?.kind_table
                              )
                              .map((commission, index) => (
                                <>
                                  <FormCommissionFgts
                                    handleOnChangeCommissionFgts={
                                      handleOnChangeCommissionFgts
                                    }
                                    insertPercentage={insertPercentage}
                                    register={register}
                                    errors={errors}
                                    index={index}
                                    commission={commission}
                                    withoutLabel={true}
                                  />
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                             <hr />
                            <h3 className="mb-4">Tabela FGTS</h3>
                            <h3 className="mb-4">R$ 51,00 a R$ 399,99</h3>
                            {fgtsCommissions
                              .filter(
                                (item) =>
                                  item.max_value === '399.99' && item.kind == corban?.kind_table
                              )
                              .map((commission, index) => (
                                <>
                                  <FormCommissionFgts
                                    handleOnChangeCommissionFgts={
                                      handleOnChangeCommissionFgts
                                    }
                                    insertPercentage={insertPercentage}
                                    register={register}
                                    errors={errors}
                                    index={index}
                                    commission={commission}
                                    withoutLabel={true}
                                  />
                                </>
                              ))}
                            <h3 className="mb-4">De R$ 400,00 a R$ 1.999,99</h3>
                            {fgtsCommissions
                              .filter(
                                (item) =>
                                  item.max_value === '1999.99' && item.kind == corban?.kind_table
                              )
                              .map((commission, index) => (
                                <>
                                  <FormCommissionFgts
                                    handleOnChangeCommissionFgts={
                                      handleOnChangeCommissionFgts
                                    }
                                    insertPercentage={insertPercentage}
                                    register={register}
                                    errors={errors}
                                    index={index}
                                    commission={commission}
                                    withoutLabel={true}
                                  />
                                </>
                              ))}
                            <h3 className="mb-4">Acima de R$ 2.000,00</h3>
                            {fgtsCommissions
                              .filter(
                                (item) =>
                                  item.min_value === '2000.0' && item.kind == corban?.kind_table
                              )
                              .map((commission, index) => (
                                <>
                                  <FormCommissionFgts
                                    handleOnChangeCommissionFgts={
                                      handleOnChangeCommissionFgts
                                    }
                                    insertPercentage={insertPercentage}
                                    register={register}
                                    errors={errors}
                                    index={index}
                                    commission={commission}
                                    withoutLabel={true}
                                  />
                                </>
                              ))}  
                          </>
                        )
                      
                    )}
                    <br></br>

                    <hr />
                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="has_cdc_commission">
                          Definir comissão CDC?
                        </Label>
                        <Input
                          type="select"
                          id="has_cdc_commission"
                          name="has_cdc_commission"
                          value={corban && corban.has_cdc_commission}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="yes">Sim</option>
                          <option value="no">Não</option>
                        </Input>
                      </Col>
                    </Row>

                    {corban.has_cdc_commission == 'yes' && (
                      <>
                        <h3 className="mb-4">Tabela CDC FGTS</h3>
                        <h3 className="mb-4">Acima de R$ 250,00</h3>
                        {cdcCommissions
                          .filter((item) => item.tac != 30)
                          .map((commission, index) => (
                            <>
                              <FormCommissionFgts
                                handleOnChangeCommissionFgts={
                                  handleOnChangeCommissionCdc
                                }
                                insertPercentage={insertPercentage}
                                register={register}
                                errors={errors}
                                withoutLabel
                                index={index}
                                commission={commission}
                              />
                            </>
                          ))}
                        <h3 className="mb-4">De R$ 100,00 a R$ 249,99</h3>
                        {cdcCommissions
                          .filter((item) => item.tac == 30)
                          .map((commission, index) => (
                            <>
                              <FormCommissionFgts
                                handleOnChangeCommissionFgts={
                                  handleOnChangeCommissionCdc
                                }
                                insertPercentage={insertPercentage}
                                register={register}
                                errors={errors}
                                withoutLabel
                                index={index}
                                commission={commission}
                              />
                            </>
                          ))}
                      </>
                    )}

                    <hr />

                    <Row>
                      <Col lg="2  mb-4">
                        <Label for="has_proposed_permission">
                          Esta entidade possui autorização para enviar propostas
                        </Label>
                        <Input
                          type="select"
                          id="has_proposed_permission"
                          name="has_proposed_permission"
                          value={corban && corban.has_proposed_permission}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="yes">Sim</option>
                          <option value="no">Não</option>
                        </Input>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Contatos</h6>
                  <div className="pl-lg-4">
                    {contactFields &&
                      contactFields.map((contactField, index) => (
                        <div key={index}>
                          <Row>
                            <Col lg="2">
                              {index == 0 && (
                                <Label for="exampleselect">
                                  Tipo de contato
                                </Label>
                              )}
                              <Input
                                type="select"
                                name="select"
                                id="exampleselect"
                                value={
                                  contactField && contactField.type_contact
                                }
                                onChange={(event) =>
                                  handleOnChangeTypeContact(event, index)
                                }
                              >
                                <option>Selecione o tipo</option>
                                <option value="email">Email</option>
                                <option value="mobile">Celular</option>
                              </Input>
                            </Col>
                            {contactField.type_contact == 'mobile' ? (
                              <Col lg="3">
                                <FormGroup>
                                  {index == 0 && (
                                    <label
                                      className="form-control-label"
                                      htmlFor="mobile_contact"
                                    >
                                      Celular
                                    </label>
                                  )}
                                  <InputMask
                                    type="text"
                                    name="mobile_contact"
                                    className="form-control form-control-alternative"
                                    id="mobile_contact"
                                    // placeholder="(99) 99999-9999"
                                    mask="(99) 99999-9999"
                                    maskChar=""
                                    value={contactField && contactField.mobile}
                                    inputRef={register({ required: true })}
                                    onChange={(event) =>
                                      handleOnChangeContact(index, event)
                                    }
                                  />
                                  {errors.mobile_contact && (
                                    <small className="text-danger">
                                      Celular é obrigatório
                                    </small>
                                  )}
                                </FormGroup>
                              </Col>
                            ) : contactField.type_contact == 'email' ? (
                              <Col lg="3">
                                <FormGroup>
                                  {index == 0 && (
                                    <label
                                      className="form-control-label"
                                      htmlFor="email_contact"
                                    >
                                      Email
                                    </label>
                                  )}
                                  <input
                                    type="email_contact"
                                    name="email_contact"
                                    className="form-control form-control-alternative"
                                    id="email_contact"
                                    value={contactField && contactField.email}
                                    // placeholder="example@example.com"
                                    ref={register({ required: true })}
                                    onChange={(event) =>
                                      handleOnChangeContact(index, event)
                                    }
                                  />
                                  {errors.email_contact && (
                                    <small className="text-danger">
                                      O email é obrigatório
                                    </small>
                                  )}
                                </FormGroup>
                              </Col>
                            ) : (
                              false
                            )}
                            <div className="mt-5">
                              <Button
                                color="danger"
                                onClick={() => handleClickContactRemove(index)}
                                close
                              />
                            </div>
                          </Row>
                        </div>
                      ))}
                    <br></br>
                    <Button
                      color="primary"
                      onClick={() => handleClickContact()}
                    >
                      Adicionar Contato
                    </Button>
                  </div>
                  <hr className="my-4" />
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <Label
                            className="form-control-label"
                            htmlFor="simplified_payment"
                          >
                            Pagamento Simplificado?
                          </Label>
                          <CustomInput
                            type="select"
                            id="simplified_payment"
                            name="simplified_payment"
                            onChange={handleOnChange}
                            value={corban && corban.simplified_payment}
                          >
                            <option value="">Selecionar</option>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                          </CustomInput>
                        </FormGroup>
                      </Col>
                      {/* {
                        corban && (corban.simplified_payment == "true" || corban.simplified_payment == true) && (
                          <Col lg="2">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="basic">
                                Valor de Corte
                              </label>
                              <Input
                                type="text"
                                name="simplified_cut_value"
                                className="form-control form-control-alternative"
                                id="simplified_cut_value"
                                onChange={handleOnChange}
                                value={corban.simplified_cut_value}
                                inputRef={register({ required: true })}
                              />
                            </FormGroup>
                          </Col>
                        )
                      } */}
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    Defina o tipo de Corban
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="2">
                        <Input
                          type="select"
                          name="type_corban"
                          id="type_corban"
                          value={corban && corban.type_corban}
                          onChange={handleOnChange}
                        >
                          <option>Selecione o tipo</option>
                          <option value="0">Interno</option>
                          <option value="1">Externo</option>
                        </Input>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">Indicador</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="referrer_id"
                            options={referrers}
                            value={
                              corban.referrer_name && {
                                value: corban.referrer_id,
                                label: corban.referrer_name,
                              }
                            }
                            onChange={handleOnChangeReferrer}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <h6 className="heading-small text-muted mb-4">Webhook</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="basic">
                            URL
                          </label>
                          <Input
                            type="text"
                            name="webhook_url"
                            className="form-control form-control-alternative"
                            id="webhook_url"
                            onChange={handleOnChange}
                            value={corban.webhook_url}
                            inputRef={register({ required: true })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> {titleButton}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CorbanForm;
