import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  Table,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  CustomInput,
} from 'reactstrap';

const TableSimulationFgts = ({
  simulationDataFgts,
  tableName,
  selectedTable,
  productId,
  selectTableFgts
}) => {
  const [showTable, setShowTable] = useState(true);

  return (
    <>
      <h3 className="mb-4 d-flex">{tableName}</h3>   
      <Table className="align-items-center justify-content-center table-flush">
        <thead className="">
          <tr>
            {/* <th scope="col">Taxa</th> */}
            <th scope="col">
            Valor Liberado
            </th>
          </tr>
        </thead>
        {simulationDataFgts &&
          simulationDataFgts
            .map((item) => (
              <tbody>
                <tr
               >
                  <td>
                    <Label check>
                        <Input
                          type="radio"
                          name="radio1"
                          checked={
                            selectedTable.product_id == productId 
                          }
                          onClick={() => {
                            console.log("SELECTED", item)
                            selectTableFgts(item)
                          }}
                        />
                      {(item.response.liquidValue/100).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                      
                    </Label>
                  </td>
                 
                </tr>
              </tbody>
            ))}
      </Table>
    </>
  );
};

export default TableSimulationFgts;
